import { Link,useStaticQuery, graphql } from "gatsby"
import React, {useEffect} from 'react'
import {AuthService, useAuth} from "gatsby-theme-sciduct"
import { GatsbyImage } from "gatsby-plugin-image"

const pageStyles = {
    color: "#eee",
    padding: 20,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
    "maxWidth": "1000px",
    "minWidth": "800px",
    "alignItems": "center",
    "align": "center",
    "margin": "auto"
}

  
function Layout({children}) {
  const {isLoggedIn} = useAuth(()=>{})

  const data = useStaticQuery(graphql`
  query LayoutPage {
    file(relativePath: {eq: "biocomplexity_2c.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300)
      }
    }
  }
  `)

  useEffect(() => {
    console.log("isLoggedIin: ", isLoggedIn)
  }, [isLoggedIn])
 

  return (
    <main style={pageStyles}>
      <div className="header">
        <h1>
          IEEE BigData 2024 Cup
        </h1>
        <h2 >Contact Network from Observed Disease Infections</h2>
        <nav className="menubar">
          
          <span><Link to="/">Home</Link></span>
          <span><Link to="/challenge">Challenge</Link></span>
          <span><Link to="/schedule">Schedule</Link></span>
          {/* <span><Link to="/faq">FAQ</Link></span>
          <span><Link to="/leaderboard">Leaderboard</Link></span>
          <span><Link to="/about">About</Link></span> */}


          <span className="userMenuItem">
            {(isLoggedIn)?(
              <button onClick={()=>{AuthService.logout()}}>LOGOUT</button>
            ):(
              <button onClick={()=>{AuthService.login(process.env.GATSBY_sciduct_application_id)}}>LOGIN</button>
            )}            
          </span>
        </nav>
  
      </div>
      <div className="mb-8">
        {children}
      </div> 
      <div className="footer">
        <GatsbyImage className="m-auto" image={data.file.childImageSharp.gatsbyImageData} alt="biocomplexity logo" /><br />
        <p><a target="_blank" rel="noreferrer" href="http://eocr.virginia.edu/notice-non-discrimination-and-equal-opportunity">Notice of Non-Discrimination and Equal Opportunity</a> | <a target="_blank" rel="noreferrer" href="http://reportabarrier.virginia.edu">Report a Barrier</a> | <a target="_blank" rel="noreferrer" href="http://www.virginia.edu/siteinfo/privacy/">Privacy Policy</a></p>
        <p>© 2024 By the Rector and Visitors of the University of Virginia</p>
      </div>      
    </main>
  )
}

export default Layout